.registration_pop_up_main_container
{
    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 11;
}
.registration_pop_up_main_container iframe
{
    width: 100%;
    height: 100%;
}