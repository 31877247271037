/* CSS Variables */
:root {
    --loader-width: 250px;
    --loader-dot-size: 20px;
  }
  
  /* General Styles */

  
  .containers {
    height: 100vh;
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100vw;
    font-family: Helvetica;
    position: relative;
    background-color: transparent;
  }
  
  /* Loader Styles */
  .loader {
    height: var(--loader-dot-size);
    width: var(--loader-width);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .loader--dot {
    animation: loader 3s ease-in-out infinite;
    height: var(--loader-dot-size);
    width: var(--loader-dot-size);
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;
  }
  
  .loader--dot:first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  
  .loader--dot:nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  
  .loader--dot:nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  
  .loader--dot:nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  
  .loader--dot:nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  
  .loader--dot:nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
  
  .loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;
  }
  
  .loader--text::after {
    content: "Loading";
    font-weight: bold;
    animation: loading-text 3s infinite;
  }
  
  /* Keyframes */
  @keyframes loader {
    15% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(calc(var(--loader-width) - var(--loader-dot-size)));
    }
    65% {
      transform: translateX(calc(var(--loader-width) - var(--loader-dot-size)));
    }
    95% {
      transform: translateX(0);
    }
  }
  
  @keyframes loading-text {
    0% {
      content: "Loading";
    }
    25% {
      content: "Loading.";
    }
    50% {
      content: "Loading..";
    }
    75% {
      content: "Loading...";
    }
  }
  