.LMS__navbar {
    width: 100%;

    position: sticky;
    top: 0;
    background-color: #f5f5f5;
    z-index: 10;
    display: flex;
}

.LMS__navbar-links {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}


.LMS__navbar-links_container {
    display: flex;
    max-width: 1400px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-block: 1.8rem;
    padding-inline: 1rem;
    column-gap: 1.5rem;
    row-gap: 0.8rem;
}


.LMS__navbar-sign a,
.LMS__navbar-menu_container a {

    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    text-decoration: none;
    margin: 0 1rem;
    cursor: pointer;
    color: rgba(59, 59, 59, 1);

}

.LMS__navbar-links_container a {
    font-size: 1.25rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    cursor: pointer;
    font-weight: 440;
    color: rgba(59, 59, 59, 1);

}

#active {
    border-bottom: 2px solid #e45f0d;
    /* background-color: rgba(243, 197, 169, 0.5); */
    color: #e45f0d;
}

.LMS__navbar-menu {
    margin-left: 1rem;
    padding-block: 1rem;
    display: none;
    position: relative;
}

.LMS__navbar-menu svg {
    cursor: pointer;
    color: black;

}

.LMS__navbar-menu_container {


    display: flex;
    flex-direction: column;
    text-align: left;
    background: #fefefe;
    padding: 2rem;
    position: absolute;
    right: 0;

    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    max-height: 80vh;
    overflow-y: auto;
}

.LMS__navbar-menu_container a {
    margin: 1rem 0;
    color: rgba(59, 59, 59, 1);
}

.LMS__navbar {}

.LMS__navbar-menu_container-links-sign {
    display: none;
}



.registrationstatus div,.participationcertificates div,
.programme div,
.conferenceschedule div {
    /* font-weight: bold; */
    /* color: #e45f0d !important; */
    /* background-color: #f0f0f0; */
    /* Light background color to make it stand out */
    /* padding: 10px; */
    /* Adding some padding for better spacing */
    /* border-radius: 5px; */
    /* Rounded corners for a smoother look */
    /* transition: background-color 0.3s ease, transform 0.3s ease; */
    /* Smooth transition for hover effects */
    /* padding-inline: 0.2rem; */
    /* position: absolute; */
    /* background-image: url("../../../public/new.gif"); */
    width: fit-content;
    position: relative;
    color: #e45f0d !important;
    font-weight: 450;

}



/* Adding an optional new badge to highlight the new items */
.registrationstatus div::after,.participationcertificates div::after,
.programme div::after,
.conferenceschedule div::after {
    content: "";
    display: inline-block;
    background-image: url("../../../public/new.gif");
    background-size: contain;
    /* Ensure the image scales properly */
    width: 30px;
    /* Adjust the width according to your image */
    height: 15px;
    position: absolute;
    right: -0.5rem;
    top: -0.22rem;
    mix-blend-mode: multiply;
    /* Adjust the height according to your image */
}



@media screen and (max-width:1500px) {
    .LMS__navbar-links_container {

        max-width: 1400px;

    }

}

@media screen and (max-width: 1050px) {
    .LMS__navbar-links_container {
        display: none;
    }

    .LMS__navbar-menu_container {
        margin-top: 4rem;
    }

    .LMS__navbar-menu svg {
        cursor: pointer;
        color: black;
        margin-right: 10px;

    }

    .LMS__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .LMS__navbar {
        padding: 2rem 4rem;
    }

    .LMS__navbar-menu_container {
        margin-top: 6rem;
        right: -4rem;
    }
}

@media screen and (max-width: 550px) {
    .LMS__navbar {
        padding: 1rem;
        padding-bottom: 0;

    }

    .LMS__navbar-menu_container {
        margin-top: 4rem;
        right: -1rem;
    }

    .banner_content h1 {
        font-size: 24px;
    }

    .conference {
        margin-right: 1rem;
    }


    .LMS__navbar-menu_container-links-sign {
        display: block;
    }

}


a {
    text-decoration: none;
}

.LMS__navbar-menu_container-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.LMS__navbar-menu_container-links a {
    margin: 0;
    font-size: 14px;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
    .LMS__navbar-menu_container {
        margin-top: 3rem;
    }
}