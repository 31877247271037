.organization_page {
    width: 100%;
    /* border:1px solid red; */
    min-height: 40vh;
    max-width: 1580px;
    margin: auto;
    padding-inline: 5rem;
    padding-block: 3rem;
    margin-bottom: 5rem;
}

.organization_page_container {
    border: 2px solid rgba(59, 59, 59, 1);
    border-radius: 0.5rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.organization_page_container h1 {
    position: absolute;
    background-color: white;
    width: fit-content;
    top: -1.8rem;
    font-family: Georama;
    text-transform: uppercase;
    color: rgba(59, 59, 59, 1);
    font-weight: 600;
    font-size: 2.23rem;
}

.ConferenceCoChairmen_comp_container {
    margin: 2.2rem;
    background-color: rgba(255, 250, 246, 1);
    border-radius: 10px;
    min-height: 20vh;
    max-width: 100%;
    min-width: 95%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    padding-inline: 2rem;
    padding-block: 1.5rem;
}

.ConferenceCoChairmen_comp_container_header {
    display: flex;
    align-items: center;
    column-gap: 0.9rem;
}

.ConferenceCoChairmen_comp_container_logo {
    background: rgba(0, 0, 0, 0.06);
    padding: 1.15rem;
    border-radius: 50%;
}

.ConferenceCoChairmen_comp_container_title h3 {
    color: rgba(24, 56, 74, 1);
    font-family: Georama;
    font-size: 28px;
    font-weight: 600;


}

.ConferenceCoChairmen_comp_container_body {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.ConferenceCoChairmen_comp_container_body ul {

    display: flex;
    flex-direction: column;
    list-style: none;
    margin-left: 0;
    gap: 1.4rem;
}

.ConferenceCoChairmen_comp_container_body ul li {
    margin-left: -2rem;
    color: rgba(59, 59, 59, 1);
    font-size: 1.06rem;
    font-weight: 500;
    text-align: left;

    font-family: 'Poppins', sans-serif;

}

.ConferenceCoChairmen_comp_container_body ul li span {
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    font-size: 1.01rem;

}

.workingMember_comp_container_body {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    column-gap: 8rem;
}

.workingMember_comp_container_body ul {

    display: flex;
    flex-direction: column;
    list-style: none;
    margin-left: 0;
    gap: 1.3rem;
    /* margin-left: -2rem; */
}

.workingMember_comp_container_body ul li {
    margin-left: -2rem !important;
    color: rgba(59, 59, 59, 1);
    font-size: 1rem;
    font-weight: 500;
    text-align: left;

    font-family: 'Poppins', sans-serif;

}

.international_advisory_list {
    display: flex;
    column-gap: 5rem;
}

.internation_advisory h2 {
    color: rgba(24, 56, 74, 1);
    font-family: Georama;
    font-size: 24px;
    font-weight: 510;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 2px solid rgba(24, 56, 74, 1);
    width: fit-content;
    margin-left: 0.4rem;
    margin-bottom: 1.8rem;
}

@media screen and (max-width: 1068px) {
    .workingMember_comp_container_body {
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .international_advisory_list {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .organization_page {

        padding-inline: 1rem;

    }

    .ConferenceCoChairmen_comp_container_logo svg {
        width: 2rem !important;
    }

    .organization_page_container h1 {

        top: -1rem;
        font-size: 1.3rem;
    }

    .ConferenceCoChairmen_comp_container_title h3 {


        font-size: 20px;



    }

    #working_member_logo svg {
        width: 5rem !important;
    }

    #national_logo {
        width: 4.7rem !important;
    }
    .organization_page_container
    {
        margin-top: 1rem;
    }
}

.working_member_heading {
    text-align: center;
    width: 100%;
    font-family: sans-serif;
    color: rgba(59, 59, 59, 1);
    margin-bottom: -1rem;
    font-weight: 410;
}
