/* .header-container{
    display: flex;
    align-items: center;
} */
.center{
   
    height: 8rem;
    margin-left: 8.5rem;

}
.header_container
{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    margin-right: 1.5rem;
}

@media screen and   (max-width:1100px){
    .center{
   
        height:5rem;
        margin-right: auto;
        margin-left: 3rem;
    
    }
}
@media screen and   (max-width:500px){
    .center{
   
        height: 5rem;
        margin-right: auto;
        margin-left: auto;
        margin-top: -1rem;
    
    }
}