.ConferenceCoChairmen_comp_container ul
{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-top: 1rem;
}
.ConferenceCoChairmen_comp_container ul li
{
    margin: 0;
  font-size: 1.15rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: rgba(59, 59, 59, 1);
}