.registration_text
{
    margin-top: 1rem;
}
.registration_text p
{
    margin: 0;
    color: rgba(255, 102, 0, 1);
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    
}
#registration_fess_body
{
    width: 100%;
    overflow: scroll;
}
.registration_fees_table_1
{
    min-width: 1000px;
}
.registration_fees_table_1_header
{
    background: rgba(0, 0, 0, 0.06);
    padding-block: 1.5rem !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
}
.registration_fees_table_1_header p
{
    font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(24, 56, 74, 1);

}
.registration_fees_table_1_body div
{
    border-bottom: 1px solid rgba(240, 235, 231, 1);
    padding-block: 0.9rem !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    
}
.registration_fees_table_1_body div p
{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
letter-spacing: 0em;
color: rgba(59, 59, 59, 1);
text-align: left;
width: 33%;
text-align: left;
margin-left: auto;
max-width: 330px;
padding-left: 1rem;
}
.registration_text_SAARC
{
    margin-top: 2.6rem;
}
.registration_text_SAARC p
{
    margin: 0;
    color: rgba(59, 59, 59, 1);
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.abstract_info{
    margin-block: 5px;
}
.abstract_info p{
    margin: 1rem;
    color: rgba(59, 59, 59, 1);
    font-size: 1.1rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.registration_text_SAARC p a{
    /* color: rgb(24, 73, 235); */
    color: #f36604 ;
    text-decoration: underline;
}
.registration_fees_table_2_header
{
    background: rgba(0, 0, 0, 0.06);
    padding-block: 1.5rem !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding-inline: 2rem;
}
.registration_fees_table_2_header p
{
    font-family: 'Poppins', sans-serif;
font-size: 16px;
font-weight: 900;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(24, 56, 74, 1);
width: 40%;

}
.registration_fees_table_2_body div
{
    border-bottom: 1px solid rgba(240, 235, 231, 1);
    padding-block: 0.9rem !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
padding-inline: 2rem;

    
}
.registration_fees_table_2_body div p
{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
letter-spacing: 0em;
color: rgba(59, 59, 59, 1);
width: 40%;
}
.registration_fees_table_2_body div p:first-of-type,.registration_fees_table_2_header p:first-of-type
{
    width: 20%;
}
.registration_notes_text
{
    margin-top: 1rem;

}
.registration_notes_text p
{
    font-size: 1rem;
    font-weight: 550;
    font-family: 'Poppins', sans-serif;
    color: rgba(59, 59, 59, 1);


}
.registration_button
{
    text-align:left;
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
}
.registration_button button
{
    color: rgba(255, 255, 255, 1);
     outline: none;
     border: none;
     background: rgba(255, 102, 0, 1);
     font-family: Georama;
     font-size: 22px;
     font-weight: 600;
     line-height: 26px;
     letter-spacing: 0em;
     text-align: left;
    padding-inline: 4.2rem;
    padding-block: 1rem;
    border-radius: 7px;
    cursor: pointer;
}
.registration_button button:hover,
.registration_button button:focus {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), none;
  background-blend-mode: normal;
  /* opacity: 0.8; */
}

.registration_fees_table_1_body
{
    width: 1000px;
    width: 100%;
   /* border: 1px solid red; */

}
.registration_fees_table_1_body table
{
   width: 100%;
   background: rgba(255, 250, 246, 1);

}
.registration_fees_table_1_body table tr td
{
    text-align: center;
    padding-block: 0.7rem;

}
.registration_fees_table_1_body table tr td:first-of-type
{
    border-bottom: 1px solid  rgba(240, 235, 231, 1);
    border-right: 1px solid  rgba(240, 235, 231, 1);
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0em;
padding: 0.4rem !important;
padding-block: 0.2rem !important;

}
.registration_fees_table_1_body table tr td:first-of-type p
{
    background: rgba(79, 97, 108, 1);
   color:rgba(255, 255, 255, 1);
   height: 100%;
   padding: 0.4rem;
   padding-inline: 1.2rem !important;
   border-radius: 4px;
}
.cancle_and_refund_container
{
    margin-top: 4rem;
}
.ConferenceCoChairmen_comp_container_logo img
{
    width: 2rem;
}
.cancle_refund_table
{
    
}

.strip
{
    background-color: #faf2ec;
  
  box-sizing: border-box;
  padding: 0.5rem;
  color: #ff3c00; 
  padding-inline: 2rem;
  border-radius: 4px;
 justify-content: space-between;
  display: flex;
  align-items: center;
}
.strip h1
{
    font-size: 1.3rem;
     margin: 0;
     font-family: Georama;
     font-weight: 510;
     text-transform: uppercase;
}
.strip svg
{
    cursor: pointer;
}
.registration_button p{
    color: #ff3c00;
    align-self: center;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
}
@media screen and (max-width:1000px) {
    .registration_button
    {
        flex-direction: column;
    }
    .registration_button p
    {
    align-self: initial;
    text-align: left;
    }
}