/* .weather_div
{
    min-width: 50.5vw;
    height: 75vh;
    background: #F6EEE9;

    overflow: hidden; 

}
.weather_header
{
    width: 80%;
    min-width: 350px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
}
.weather_header_location,.weather_header_date
{
    display: flex;
    column-gap: 0.7rem;
}
.weather_header_location h1 ,.weather_header_date h1 
{
    font-size: 1.5rem;
    font-weight: 550;
    font-family: 'Georama';
}
.weather_header_location h1 span ,.weather_header_date h1 span
{
  font-weight: 400;
}
.weather_date_icon,.weather_location_icon
{
    margin-top: 0.5rem;
}
.weather_details
{
    width: 80%;
    min-width: 350px;
    margin: auto;
    border-block: 1.5px solid #D0D0D0;
    margin-block: 2.7rem;
}
.weather_details_day
{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-block: 0.5rem;
}
.weather_details_day p
{

    border-radius: 5px;
    padding-inline: 1rem;
    padding-block: 0.6rem;
    margin-block: 0.5rem;
    cursor: pointer;
}
.day
{
    background: rgba(255, 102, 0, 0.09);

}
.weather_details_list
{
    display: flex;
    align-items: center;
    padding-block: 2rem;
    justify-content: space-between;
}
.weather_details_item
{
    min-width: 250px;
 
}
.weather_details_item
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.weather_details_item p
{
    color: #ADADAD;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    
}
.weather_details_item h4
{
    color: #ADADAD;
    font-size: 1.4rem;
    font-weight: 440;
  margin-top: 1.3rem;
}
.weather_details_list2
{
    display: flex;
    align-items: center;
    justify-content: center; 
}
.all_rights_reserve
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.28rem;
    margin-right: auto;
    margin-top:2rem;
}
.all_rights_reserve p
{
  color: white;
  font-size: 1rem;
  padding: 0;
}
.all_rights_reserve img
{
    margin: 0;

} */

.weather_div
{
    min-width: 40.5vw;
    background: hsla(23,42%,94%,.1);
    border-bottom-left-radius: 0.3rem;
    background-color: hsl(46deg 29% 31% / 35%);
    border-bottom-left-radius: 8px;
    backdrop-filter:  blur(5px);
}
.weather_header
{
    width: 80%;
    min-width: 350px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
}
.weather_header_location,.weather_header_date
{
    display: flex;
    column-gap: 0.7rem;
}
.weather_header_location h1 ,.weather_header_date h1 
{
    font-size: 1.5rem;
    font-weight: 550;
    font-family: 'Georama';
    color: white;
    
}
.weather_header_location h1 span ,.weather_header_date h1 span
{
  font-weight: 400;
}
.weather_date_icon,.weather_location_icon
{
    margin-top: 0.5rem;
}
.weather_details
{
    width: 80%;
    min-width: 350px;
    margin: auto;
    /* border-block: 1.5px solid #D0D0D0; */
    margin-block: 2.7rem;
    margin-bottom: 0rem;
}
.weather_details_day
{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-block: 0.5rem;
}
.weather_details_day p
{

    border-radius: 5px;
    padding-inline: 1rem;
    padding-block: 0.6rem;
    margin-block: 0.5rem;
    cursor: pointer;
     color: white;
   
}
.day
{
    background: rgba(255, 102, 0, 0.8);

}
.weather_details_list
{
    display: flex;
    align-items: center;
    padding-block: 4rem;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;
}
.weather_details_item
{
    /* min-width: 250px; */
    margin-top: 1rem;
}
.weather_details_item
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px;
}
.weather_details_item p
{
    /* color: #ADADAD; */
     color: white;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    
}
.weather_details_item h4
{
    /* color: #ADADAD; */
    color: white;

    font-size: 1.4rem;
    font-weight: 440;
  margin-top: 1.3rem;
}
.weather_details_list2
{
    display: flex;
    align-items: center;
    padding-block: 4rem;
    padding-top: 1rem;
    justify-content: center; 
}
@media screen and (max-width:1180px) {
    .weather_div
{
    min-width: 100%;
    

}
.footer_section2
{
    width: 100%;
    margin-top: 2rem;
}
.footer_section2_second_wrapper
{
    margin: auto;
    padding-bottom: 2rem;
}
}