.footer_container {
    background-image: url('../../assets/Footer1.png');
    background-size: cover;
    background-position: center;
    margin-top: 5rem;
    background-color: black;
    width: 100%;
    /* min-height: 92vh; */
    overflow: hidden;
}

.footer_content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    text-align: left;
    padding-bottom: 1.5rem;

}

.footer_section1 {
    /* margin-top: 2rem; */
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    padding: 5rem;
    padding-top: 2rem;
    padding-bottom: 0 !important;
}

.footer_subscribe {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.footer_subscribe input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: white;
    border: 2px solid white;
    padding: 0 1rem;
    outline: none;
    min-width: 200px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.footer_subscribe button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    border: 2px solid #FF6600;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    background: #FF6600;
    border-radius: 7px;
    margin-left: -0.5rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.footer_section2 {
    display: flex;
    flex-direction: column;
    /* margin-right: 3rem;   */
    /* margin-top: 3rem;   */
}

.footer_section1 h4 {
    font-family: 'Georama';
    font-style: normal;
    font-weight: 500;
    color: #fff;
    font-size: 20px;
    margin-bottom: 1rem;
}

.footer_section1 h2 {
    font-family: 'Georama';
    font-style: normal;
    font-weight: 500;
    color: #fff;
    font-size: 29px;
    margin-bottom: 1rem;

}

.footer_section2 h3 {
    font-family: 'Georama';
    font-style: normal;
    font-weight: 500;
    color: #fff;
    font-size: 25px;
    margin-bottom: 1rem;

}

.footer_button {
    display: flex;
    flex-direction: row;

}

.lft {
    margin-right: 3rem;
}

.google_map {
    border-radius: 9px;
}

.subscribe_text {
    margin-top: 2rem;
}

@media screen and (max-width: 550px) {
    .footer_content {
        padding: 0;

        /* padding-right: 1.5rem; */

    }

    .footer_section1 {
        padding: 0;

        padding-right: 1.5rem;
        padding-top: 1rem;
    }

    .footer_section2_second_wrapper {
        padding-inline: 1rem;
    }

}

@media screen and (min-width: 300px) and (max-width: 425px) {
    .footer_section2 {
        display: none;

    }
}