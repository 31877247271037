.conference_venu_text
{
    margin-top: 1rem;
}
.conference_venu_text p
{
    margin: 0;
    font-size: 1.15rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif; 
    color: rgba(59, 59, 59, 1);

}
.conference_venu_images
{
    display: flex;
    column-gap: 1rem;
    margin-block: 2.6rem;
    
}

.conference_venu_images>div
{
    width: 50%;
}
.conference_venu_images_box2_1
{
    display: flex;
    flex-direction: column;
    row-gap: 0.9rem;
}
.conference_venu_images_box2
{
    display: flex;
    column-gap: 1rem;

}
.how_to_reach_container h2
{
    font-family: Georama;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(24, 56, 74, 1);
    
}
.how_to_reach_container_body
{
    display: flex;
    margin-top: 1rem;
    column-gap: 1rem;
}
.how_to_reach_container_body>div
{
    width: 50%;
}
.how_to_reach_container_body_map
{

}
.how_to_reach_container_body_text p
{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: justified;
color: rgba(59, 59, 59, 1);

}
.reach_email,.reach_venu
{
    margin-top: 1rem;
}
.reach_email h4,.reach_venu h4
{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;


}
.reach_email p,.reach_venu p
{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

@media screen and (max-width: 1100px) {
    .conference_venu_images
{
   flex-direction: column;
}
.conference_venu_images>div
{
    width: 100%;

}
.conference_venu_images_box1 img
{
    width: 100%;
}
.how_to_reach_container_body
{
    flex-direction: column;
    gap: 2rem;
}
.how_to_reach_container_body>div
{
    width: 100%;
}
}